$breakpoint-ipadpro: 1024px;
$breakpoint-ipad: 768px;
$breakpoint-iphoneX: 375px;
$breakpoint-iphone6-7-8: 414px;

.App {
  width: 100%;
}

.App-header {
  width: 100%;
}

body {
  font-family: "Open Sans", sans-serif;
  background-color: #fdfdfd;
  height: 100vh;
}

.homeSection {
  position: relative;
  top: 35px;
  display: flex;
  font-size: 25px;
  height: calc(100vh - 35px);
  @media (max-width: $breakpoint-iphone6-7-8) {
    height: calc(100vh - 50px);
    top: 5px;
  }
  .homeTextContainer {
    align-items: center;
    vertical-align: middle;
    line-height: normal;
    text-align: center;
    margin: auto;
    width: 50%;
  }

  .profilePic {
    position: relative;
    box-sizing: border-box;
    height: 100%;
    img {
      position: relative;
      height: 100%;
      max-width: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }
}

section.aboutSection {
  position: relative;
  padding-top: 10px;
  padding: 100px;
  padding-bottom: 10px;
  display: flex;
  justify-content: left;
  text-align: left;
  font-size: 20px;
  div.aboutContainer {
    display: flex;
    justify-content: left;
    flex-direction: row;
    div.aboutTextContainer {
      display: inline-block;
      vertical-align: middle;
      line-height: normal;
      padding: 25px;
      width: 50%;
      margin: 20px;
      text-align: left;
      margin: 0;
      display: flex;
      flex-direction: column;
      .alink {
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }
}

h1 {
  padding: 0 0 10px 0;
  font-family: "Open Sans", sans-serif;
  font-size: 2rem;
  margin: 0;
}

.aboutTitle {
  text-align: left;
  float: left;
}

.faImages {
  flex-direction: row;
  .icon {
    padding: 5px;
    height: 35px;
    width: 35px;
    color: black;
    cursor: pointer;
    &:hover {
      color: grey;
    }
  }
}

.aboutSkill {
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  max-width: 50%;
  border-radius: 5px;
  opacity: 0.9;
  padding-bottom: 0px;
  .skillDiv {
    padding: 15px;
    font-size: 15px;
    .skillIcon {
      padding: 15px;
      height: 50px;
      width: 50px;
      color: black;
    }
  }
}

@media (max-width: $breakpoint-ipadpro) {
  section.homeSection {
    height: 100vh;
    display: flex;
    flex-direction: column;
    font-size: 30px;
    overflow: hidden;
    div.homeTextContainer {
      display: inline-block;
      align-items: center;
      vertical-align: middle;
      line-height: normal;
      text-align: center;
      margin: auto;
      // font-size: 40px;
      padding: 50px;
    }
  }

  img.profilePic {
    display: flex;
    min-height: 1100px;
    max-width: inherit;
    margin: 0;
  }

  section.aboutSection {
    margin: 0;
    padding: 20px;
    padding-top: 60px;
    // padding-bottom: 20px;
    div.aboutContainer {
      display: flex;
      flex-direction: column-reverse;
      div.aboutTextContainer {
        width: auto;
        font-size: 20px;
      }
    }
  }

  div.aboutSkill {
    // width: auto;
    max-width: fill;
  }
}

@media (max-width: $breakpoint-ipad) {
  .App-header {
    width: 100%;
  }

  section.homeSection {
    padding-top: 35px;
  }

  img.profilePic {
    min-height: 800px;
  }

  section.aboutSection {
    margin: 0;
    div.aboutContainer {
      display: flex;
      flex-direction: column-reverse;
      div.aboutTextContainer {
        width: auto;
        font-size: 28px;
      }
    }
  }

  div.aboutSkill {
    max-width: fill;
  }
}

@media (max-width: $breakpoint-iphone6-7-8) {
  .App {
    width: 100%;
  }

  .App-header {
    width: 100%;
  }

  section.homeSection {
    height: 100vh;
    width: 100%;
    // padding: 10px;
    padding-top: 35px;

    div.homeTextContainer {
      font-size: 20px;
      display: inline-block;
      align-items: center;
      vertical-align: middle;
      line-height: normal;
      text-align: center;
      padding: 40px;
    }
    img.profilePic {
      display: flex;
      // min-height: ;
      max-width: 414px;
      min-height: 30px;
    }
  }

  section.aboutSection {
    margin: 0;
    padding: 10px;
    // padding-bottom: 20px;

    div.aboutContainer {
      display: flex;
      flex-direction: column-reverse;
      div.aboutTextContainer {
        padding-top: 70px;
        width: auto;
        font-size: 20px;
        text-align: justify;
      }
    }
  }

  h1 {
    font-size: 2rem;
  }

  div.aboutSkill {
    // width: auto;
    max-width: fill;
  }

  .faImages {
    padding-left: 0px;
    flex-direction: column;
    justify-content: right;
    align-items: right;
    .icon {
      height: 40px;
    }
  }

  .aboutSkill {
    .skillDiv {
      // padding: 10px;
      font-size: 14px;
      .skillIcon {
        padding: 10px;
        height: 40px;
        width: 40px;
      }
    }
  }
}

.footer {
  width: 100%;
  height: 10vh;
  vertical-align: bottom;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footerNavLink {
  &:hover {
    text-decoration: underline;
  }
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: all 0.5s ease-in-out;
}

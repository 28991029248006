$breakpoint-ipad: 1200px;
$breakpoint-iphone6-7-8: 767px;

.projectsNavSection {
  padding-top: 50px;
}

.projectsSection {
  width: 100%;
}

.portfolioProjectsSection {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  max-width: 100%;
  align-items: center;
  justify-content: center;
  margin: auto auto;
}

.projectsHeader {
  display: flex;
  flex-direction: column;
  width: 87%;
  @media (max-width: $breakpoint-ipad) {
    width: 95%;
  }
  @media (max-width: $breakpoint-iphone6-7-8) {
    width: 89%;
  }
}

.h1 {
  font-size: 1rem;
  @media (max-width: $breakpoint-ipad) {
    font-size: 18px;
    padding: 25px;
  }
  @media (max-width: $breakpoint-iphone6-7-8) {
    // font-size: 15px;
    padding-left: 18px;
  }
}

.projectsIntro {
  font-size: 20px;
  @media (max-width: $breakpoint-ipad) {
    font-size: 18px;
  }
  @media (max-width: $breakpoint-iphone6-7-8) {
    font-size: 15px;
    // padding-left: 18px;
  }
}

.projectContainer {
  box-sizing: border-box;
  position: relative;
  flex: 1 1 100%;
  width: 100%;
  max-width: 45%;
  padding: 20px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  @media (max-width: $breakpoint-ipad) {
    max-width: 100%;
  }
  @media (max-width: $breakpoint-iphone6-7-8) {
    max-width: 100%;
  }
}

.individualProjectHeader {
  display: flex;
  justify-content: space-between;
  .projectLinks {
    .projectIcon {
      padding: 5px;
      height: 35px;
      width: 35px;
      @media (max-width: $breakpoint-iphone6-7-8) {
        height: 28px;
        width: 28px;
      }
      &:hover {
        color: grey;
        cursor: pointer;
      }
    }
  }
}

.coverImageContainer {
  position: relative;
  width: 100%;
  object-fit: cover;
  &:hover {
    .coverImageText {
      opacity: 0.9;
      font-size: 20px;
      padding: 20px;
      text-align: justify;
      @media (max-width: $breakpoint-iphone6-7-8) {
        font-size: 15px;
        padding: 5px;
      }
    }
  }
}

.projectTitle {
  bottom: 0;
  font-size: 1.8rem;
  @media (max-width: $breakpoint-iphone6-7-8) {
    font-size: 20px;
  }
}

.projectImage {
  position: relative;
  opacity: 1;
  display: block;
  width: 100%;
  height: 100%;
  transition: 0.5s ease;
  backface-visibility: hidden;
}

.coverImageText {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  background: lightgrey;
  overflow-y: scroll;
}

$breakpoint-ipad: 1200px;
$breakpoint-iphone6-7-8: 767px;

.contactSection {
  padding-top: 80px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  flex-direction: column;
  .title {
    padding-left: 58px;
    padding-bottom: 0px;
    display: flex;
    width: 87%;
    @media (max-width: $breakpoint-ipad) {
      width: 95%;
    }
    @media (max-width: $breakpoint-iphone6-7-8) {
      // width: 100%;
      padding-left: 50px;
    }
  }
}

.emailConfirmation {
  font-size: 22px;
  padding-left: 58px;
  padding-bottom: 0px;
  display: flex;
  width: 87%;
  @media (max-width: $breakpoint-ipad) {
    width: 95%;
  }
  @media (max-width: $breakpoint-iphone6-7-8) {
    width: 89%;
    font-size: 16px;
  }
}

.contactContainer {
  position: relative;
  display: flex;
  padding: 0px;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  font-size: 22px;
  @media (max-width: $breakpoint-ipad) {
    flex-direction: column;
    font-size: 22px;
  }
  @media (max-width: $breakpoint-iphone6-7-8) {
    flex-direction: column;
    font-size: 18px;
  }
}

.contactHolder {
  position: relative;
  box-sizing: border-box;
  margin: auto;
  width: 50%;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  justify-content: center;
  padding: 30px 30px;
  @media (max-width: $breakpoint-ipad) {
    width: 100%;
    padding-left: 30px;
    align-items: center;
  }
  @media (max-width: $breakpoint-iphone6-7-8) {
    width: 100%;
  }
}

.contactText {
  position: relative;
  box-sizing: border-box;
  text-align: justify;
  min-width: 50%;
  padding: 20px;
  a:link {
    color: black;
    text-decoration: none;
  }
  @media (max-width: $breakpoint-ipad) {
    min-width: 60%;
    max-width: 60%;
  }
  @media (max-width: $breakpoint-iphone6-7-8) {
    min-width: 100%;
    padding: 10px;
  }
}

.icon {
  display: inline-block;
  vertical-align: middle;
  padding: 10px;
  height: 50px;
  width: 50px;
  cursor: pointer;
  &:hover {
    color: grey;
  }
  @media (max-width: $breakpoint-iphone6-7-8) {
    height: 38px;
    width: 38px;
  }
}

#contactForm {
  box-sizing: border-box;
  vertical-align: middle;
  margin: 0 auto;
  float: right;
  padding: 20px;
  width: 50%;
  @media (max-width: $breakpoint-ipad) {
    width: 100%;
  }
  @media (max-width: $breakpoint-iphone6-7-8) {
    width: 100%;
  }
}

#contactForm input,
textarea {
  letter-spacing: 2px;
  font: 200 1em/1.1em "Helvetica Neue", sans-serif;
  box-sizing: content-box;
  color: #000;
  background-color: #d7d7d7;

  outline: none;
  border: none;

  display: block;
  margin: 0 auto;
  padding: 1em;
  width: 90%;
  max-width: 500px;
}

#contactForm textarea {
  height: 150px;
}

#contactForm *:focus {
  background-color: lightgrey;
}

#contactForm *:hover {
  background-color: lightgrey;
}

$breakpoint-iphone6-7-8: 767px;

body {
  margin: 0;
}

.navbar {
  position: fixed; /* Set the navbar to fixed position */
  z-index: 999;
  top: 0; /* Position the navbar at the top of the page */
  width: 100%; /* Full width */
  display: flex;
  background: white;
  color: white;
  justify-content: center;
  box-sizing: border-box;
  border-bottom: 0.5px solid rgb(221, 221, 221);
  height: 35px;
  @media (max-width: $breakpoint-iphone6-7-8) {
    height: 50px;
  }
}

.sticky {
  position: fixed;
  top: 0;
  // width: 100%;
}

.sticky + .content {
  padding-top: 20px;
}

.navButton {
  position: relative;
  flex: 1 1 100%; // responsive
  height: 100%;
  font-size: 18px;
  color: black;
  &:hover {
    background-color: lightgrey;
  }
  @media (max-width: $breakpoint-iphone6-7-8) {
    font-size: 19px;
  }
}

.navLink {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s ease-in-out;
}
